// Google Fonts.
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,500&display=swap');

// Font Awesome fonts.
@import "~@fortawesome/fontawesome-free/scss/solid";

// Custom variables.
$color-tendo-blue: #0047ab;

// Overwrite bootstrap default varibales with custom values. Bootstrap uses
// "blue" as the "primary" colour. We treat the "info" button as a secondary
// style for effectively primary buttons.
$blue: $color-tendo-blue;
$red: #ee4747;
$info: #0067f8;

$font-family-sans-serif: Montserrat, -apple-system, BlinkMacSystemFont, sans-serif;
$text-muted: #aab4c2;

$input-btn-padding-x-lg: 1rem;
$input-btn-padding-y-lg: 1rem;
$input-btn-font-size-lg: 1rem;
$input-border-color: #aab4c2;

$navbar-dark-color: #fff;

// Bootstrap 4.
@import "~bootstrap/scss/bootstrap";

// Custom components and Bootstrap overrides.
@import "layout";
@import "navbar";
@import "dropdown";
@import "forms";
@import "tags";
@import "stamps";
@import "courses";

html, body {
  height: 100%;
}

// Spinner.
.busy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  &:after {
    $spinner-size: 200px;

    content: '';
    background-image: url('../images/spinner.svg');
    position: absolute;
    width: $spinner-size;
    height: $spinner-size;
    top: 50%;
    left: 50%;
    margin-top: -($spinner-size / 2);
    margin-left: -($spinner-size / 2);
  }
}

// Dashboard.
.page-dashboard {
  .skills-summary {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }

  .courses {
    ul {
      margin-bottom: 0;
    }
  }
}

// Required to decrease the size of the sync icon imported from font-awesome.
.fa-xs {
  font-size: 0.70em;
}

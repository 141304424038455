.navbar-dark {
  background: linear-gradient(to right, darken($link-color,5%), darken($link-color,10%));

  .navbar-brand {
    padding: 0 10px 5px 0;
    margin: 0;

    img {
      width: 134px;
    }
  }

  .btn-cta {
    -webkit-order: 3;
    order: 3;

    .plus-sign {
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 7px;
        height: 2px;
        left: 0;
        right: 0;
        background: rgba(#fff, 0.8);
      }

      &:after {
        content: "";
        position: absolute;
        left: 7px;
        width: 2px;
        top: 0;
        bottom: 0;
        background: rgba(#fff, 0.8);
      }
    }
  }

  .navbar-toggler {
    width: 44px;
    height: 44px;
    padding: 0;
    position: relative;
    border: none;

    span {
      position: absolute;
      left: 15%;
      width: 60%;
      height: 2px;
      background: rgba(#fff, 0.9);

      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 120%;
        height: 2px;
        background: rgba(#fff, 0.9);
        margin-top: -7px;
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 80%;
        height: 2px;
        background: rgba(#fff, 0.9);
        margin-top: 7px;
      }
    }
  }

  .nav-link {
    color: #fff !important;
  }

  @media (min-width: 768px) {
    .nav-link {
      border-radius: 5px;

      &:hover {
        background: rgba(lighten($link-color, 30%), 0.1)
      }
    }
  }

  @media (max-width: 767.98px) {
    padding: 0.5rem;

    .navbar-brand {
      position: absolute;
      left: 50%;
      top: 7px;
      transform: translateX(-50%);
    }

    .navbar-collapse {
      -webkit-order: 4;
      order: 4;
      margin: 7px -0.5rem 0 !important;
    }

    .nav-link {
      background: linear-gradient(to right, $link-color, darken($link-color, 5%));
      padding: 0.75rem 1rem;
      position: relative;
      border-bottom: 1px solid rgba(#fff, 0.1);

      &:after {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -7px;
      }

      &:hover {
        background: linear-gradient(to right, lighten($link-color, 5%), $link-color);
      }
    }

    .dropdown-menu {
      background: transparent;
      box-shadow: none;
      padding: 0;

      .dropdown-item {
        border-radius: 0 !important;
        padding: 0.75rem 1rem;
        color: #fff !important;
        background: linear-gradient(to right, darken($link-color, 5%), darken($link-color, 10%));

        &:hover {
          background: linear-gradient(to right, $link-color, darken($link-color, 5%));
        }
      }
    }
  }
}

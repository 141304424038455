.dropdown-menu {
  border: none;
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.075);
  padding: 0;

  .dropdown-item {
    font-size: 0.9rem;
    padding: 0.75rem 1rem;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.dropdown-toggle-alt {
  &:after {
    content: "";
    display: inline-block;
    margin-left: 0.35rem;
    vertical-align: 0.25rem;
    width: 8px;
    height: 8px;
    border-bottom: 1px solid currentColor;
    border-right: 1px solid currentColor;
    transform: rotate(45deg);
  }
}

.dropdown-lang {
  display: block;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;

  img {
    width: 14px;
    height: 14px;
    margin-right: 7px;
  }
}

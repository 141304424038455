.tag-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

  &:after {
    content: " ";
    -webkit-flex: 1000 0 auto;
    flex: 1000 0 auto;
  }

  .tag-item {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
    white-space: nowrap;
    text-align: center;
    margin: 0 3px 5px;
    font-size: 0.85rem;
    display: inline-block;
    padding: 0.5rem 0.75rem;
    border-radius: 3px;
    border: 1px solid #d9dee6;
    color: #6c7b91;
    background: #fdfdfd;

    &:hover {
      color: lighten($link-color,15%);
    }

    &:first-child {
      margin-left: 0;
    }

    &.dark {
      background-color: #343a40;
     color: #fff;
    }
  }
}

// Custom styles for the react-tagsinput component.
.react-tagsinput-tag {
  background-color: $blue;
  border-radius: 3px;
  border: 0;
  color: white;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  margin-top: 4px;
}

.react-tagsinput {
  border-color: $input-border-color;
  border-radius: $input-border-radius;
  min-height: $input-height-lg;
  padding: 4px 1rem;

  &--focused {
    box-shadow: $input-focus-box-shadow;
    border-color: $input-focus-border-color;
  }

  .react-tagsinput-input {
    color: $input-color;
    font-family: inherit;
    font-size: 1rem;
    width: 150px;
  }
}

.form-group {
  label {
    font-weight: $font-weight-bold;
  }
}

.form-feedback,
.form-text {
  text-align: left;
  font-style: italic;
  font-weight: 400;
}

// Custom radio button.
.custom-radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 20px;

  & + .custom-radio {
    margin-top: 30px;

    &:after {
      content: "";
      display: block;
      width: 80%;
      height: 1px;
      background: $input-border-color;
      position: absolute;
      top: -15px;
      left: 10%;
    }
  }

  input[type="radio"] {
    opacity: 0;
    z-index: -1;
    position: absolute;

    &:checked {
      + span {
        color: #0047ab;
        font-weight: 500;

        &:before {
          border: 1px solid #0047ab;
        }

        &:after {
          content: "";
        }
      }
    }
  }

  span {
    display: block;
    line-height: 20px;
    margin-left: 20px;
    padding-left: 7px;
    text-align: left;

    i {
      color: #7d8da2;
      font-size: 0.8rem;
      font-weight: 400;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      border-radius: 50%;
      background: #fdfdfd;
      border: 1px solid $input-border-color;
    }

    &:after {
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      left: 4px;
      top: 4px;
      border-radius: 50%;
      background: #0047ab;
    }
  }
}

.radio-group {
  border: 1px solid $input-border-color;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

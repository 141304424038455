.page-courses-list {
  h2 {
    margin-bottom: 1rem;
  }

  .courses {
    margin-bottom: 1rem;

    ul {
      margin-bottom: 0;
    }
  }

  .course-name {
    font-weight: bold;
  }

  .enrolment-status-sent {
    font-style: italic;
    color: #aab4c2;
  }
}

.page-courses-list {
  .courses {
    ul {
      margin-bottom: 0;
    }
  }
}

.page-course-view {
  .module-list {
    $icon-margin-right: 12px;
    $icon-size: 39px;

    list-style: none;
    counter-reset: module-list-counter;

    li {
      counter-increment: module-list-counter;
      line-height: 38px;
      padding-bottom: 16px;
      padding-left: $icon-margin-right;

      &:last-child {
        padding-bottom: 0;
      }

      & > * {
        line-height: 24px;
      }

      &:before {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -$icon-size - $icon-margin-right;
        border-radius: 100%;
        width: $icon-size;
        height: $icon-size;
        position: absolute;
      }

      .tag-group {
        margin-bottom: 1rem;
      }
    }

    li.incomplete {
      &:before {
        content: counter(module-list-counter);
        background-color: #AAB4C2;
      }
    }

    li.verified {
      &:before {
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
        content: "\f00c";
        color: #fff;
        background-color: #155724;
      }
    }

    li.sent {
      &:before {
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
        content: "\f1d8";
        color: #fff;
        background-color: #212529;
      }
    }
  }
}

// Containers
$container_sizes: (
  sm: 696,
  xs: 576,
  xxs: 434,
);

.container {
  width: 100%;
  max-width: 960px;
}

@each $size, $width in $container_sizes {
  .container-#{$size} {
    width: 100%;
    max-width: #{$width}px;
    margin: 0 auto;
  }

  .override-container {
    .container-#{$size} {
      max-width: 960px;
    }
  }
}

.row-sm {
  margin-left: -10px;
  margin-right: -10px;

  > [class^=col] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

// Boxes, sit inside containers.
.box {
  background: $white;
  border-radius: 5px;
  box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, 0.075);
  margin-bottom: 1.5rem;
}


.stamp-title {
  margin-bottom: 1.5rem;
  border-bottom: 2px dotted #eee;

  h6 {
    text-transform: uppercase;
  }
}

.stamp-skill {
  border: 1px solid black;
  display: inline-block;
  margin: 5px 10px 5px 0;
  padding: 5px;
  border-radius: 4px;
  min-width: 40px;
  text-align: center;
}

.stamp-phonenumber {
  margin-right: 5px;
}

.stamp-icon {
  &__sent {
    color: #49CB17;
  }

  &__verified {
    color: #007aff;
  }

  &__rejected {
    color: #FF0221;
  }
}

.stamp-type-toggle {
  margin-bottom: 1rem;
}
